<template>
  <div class="info-page">
    <div class="info-page__header">
      <div class="logo">
        <img class="logo__img" src="@/assets/img/logo_full.svg" />
      </div>
    </div>
    <div
      class="info-page__wrapper"
      :class="{ expired: expiredStatus, promotion: isPromotion }"
    >
      <div class="common-container">
        <div class="info-page__promotion" v-if="isPromotion">
          <div class="info-page__promotion-list">
            <div
              class="item"
              v-for="(item, index) in promotionData"
              :key="index"
            >
              <div class="item__img" v-if="item.image_url">
                <img :src="item.image_url" alt="" />
              </div>
              <div class="item__data">
                <div class="item__data-type" v-if="item.message_type">
                  {{ item.message_type }}
                </div>
                <div class="item__data-headline" v-if="item.headline">
                  {{ item.headline }}
                </div>
                <div
                  class="item__data-message"
                  v-if="item.message"
                  v-html="item.message"
                ></div>
                <div class="item__data-link-wrap" v-if="item.action_button_url">
                  <a
                    target="_blank"
                    :href="
                      isAndroidDevice && item.action_button_url_android
                        ? item.action_button_url_android
                        : isIOSDevice && item.action_button_url_iphone
                        ? item.action_button_url_iphone
                        : item.action_button_url
                    "
                    >{{ item.action_button_text }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-page__content">
          <div class="info-page__icon" :class="{ promotion: isPromotion }">
            <img
              v-if="icon"
              src="@/assets/img/icons/check-mark-icon.svg"
              alt=""
            />
            <img v-else src="@/assets/img/icons/reject.png" alt="" />
          </div>
          <div v-if="expiredStatus" class="info-page__title">
            {{ message }}
          </div>
          <div v-if="expiredStatus" class="info-page__desc">
            If you need assistance, please reach out to Social Wonder’s team.
          </div>
          <div v-else class="info-page__title">
            Thanks {{ userName }}! <br />
            {{ message }}
          </div>
          <div v-if="!expiredStatus" class="info-page__desc">
            We will schedule the approved posts for next week.
          </div>
          <div class="info-page__link" v-if="$route.params.id">
            <router-link
              :to="{
                name: 'previous-feedback',
                params: { id: $route.params.id },
              }"
              >Load previous feedback</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <Referrals
      v-if="!isPromotion"
      id="referralContainer"
      :customerName="customerName"
    />
  </div>
</template>

<script>
import Referrals from "@/components/global/Referrals";
import { mapActions } from "vuex";
import { isAndroid, isIOS } from "mobile-device-detect";

export default {
  name: "ClientStatus",
  components: { Referrals },
  data: () => ({
    promotionData: null,
    isPromotion: false,
  }),
  computed: {
    isAndroidDevice() {
      return isAndroid;
    },
    isIOSDevice() {
      return isIOS;
    },
    icon() {
      return this.$route.params.statusType !== "expired";
    },
    expiredStatus() {
      return this.$route.params.statusType === "expired";
    },
    message() {
      return this.$route.params.statusType === "expired"
        ? "The link is no longer valid"
        : this.$route.params.statusType === "done"
        ? "We received your feedback."
        : "Your feedback has been sent.";
    },
    userName() {
      return this.$route.params.userName || "";
    },
    customerName() {
      return this.$route.params.customerName || "";
    },
    params() {
      return this.$route.params;
    },
  },
  mounted() {
    this.getPromotionInfo();
  },
  methods: {
    ...mapActions("clientDashboard", ["fetchPromotionData"]),
    getPromotionInfo() {
      let data = sessionStorage.getItem("promotion");
      if (JSON.parse(data).promotionItems.length) {
        this.isPromotion = true;
        this.promotionData = JSON.parse(data).promotionItems;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.info-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__header {
    width: 100%;
    height: 70px;
    padding: 20px;
    background: $color-white;
    .logo {
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
      }
    }
  }
  &__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    background: #f3f2f3;
    padding: 20px;
    background: #f3f2f3 url("../../assets/img/congrat-bg.svg") no-repeat center
      bottom;
    background-size: contain;
    min-height: calc(100vh - 70px);
    &.expired {
      background: #f3f2f3;
    }
    &.promotion {
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
    }
    .common-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
    }
  }
  &__content {
    width: 100%;
    max-width: 340px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    width: 124px;
    height: 124px;
    margin-bottom: 30px;
    &.promotion {
      @media screen and (max-width: 600px) {
        width: 73px;
        height: 73px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: $color-black;
    margin-bottom: 12px;
  }
  &__desc {
    font-size: 14px;
    line-height: 160%;
    color: $color-black;
    opacity: 0.8;
    text-align: center;
  }
  &__link {
    position: absolute;
    bottom: 100px;
    @media screen and (max-height: 620px) {
      position: static;
      margin-top: 30px;
    }
    a {
      color: #3279de;
      font-size: 14px;
    }
  }
  &__promotion {
    width: 40%;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__promotion-list {
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      max-width: 571px;
      display: inline-flex;
      background: #fff;
      border: 1px dashed rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      &__img {
        padding: 10px;
        width: 150px;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        border-right: 1px dashed rgba(0, 0, 0, 0.4);
        @media screen and (max-width: 600px) {
          width: 119px;
        }
        img {
          width: 100%;
        }
      }
      &__data {
        padding: 15px 20px;
        @media screen and (max-width: 600px) {
          padding: 5px 20px;
        }
        &-type {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 0.005em;
          color: #92c4ff;
          text-transform: capitalize;
          @media screen and (max-width: 600px) {
            font-size: 12px;
            line-height: 140%;
          }
        }
        &-headline {
          font-weight: 600;
          font-size: 24px;
          line-height: 140%;
          color: #333333;
          @media screen and (max-width: 600px) {
            font-size: 15px;
            line-height: 140%;
          }
        }
        &-message {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #333333;
          opacity: 0.8;
          border-bottom: 1px solid rgba(51, 51, 51, 0.8);
          padding-bottom: 5px;
          @media screen and (max-width: 600px) {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
          }
        }
        &-link-wrap {
          text-align: right;
          padding-top: 4px;
          @media screen and (max-width: 600px) {
            padding-top: 0;
          }
          a {
            text-decoration: none;
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: #92c4ff;
            @media screen and (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
