var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-page"},[_vm._m(0),_c('div',{staticClass:"info-page__wrapper",class:{ expired: _vm.expiredStatus, promotion: _vm.isPromotion }},[_c('div',{staticClass:"common-container"},[(_vm.isPromotion)?_c('div',{staticClass:"info-page__promotion"},[_c('div',{staticClass:"info-page__promotion-list"},_vm._l((_vm.promotionData),function(item,index){return _c('div',{key:index,staticClass:"item"},[(item.image_url)?_c('div',{staticClass:"item__img"},[_c('img',{attrs:{"src":item.image_url,"alt":""}})]):_vm._e(),_c('div',{staticClass:"item__data"},[(item.message_type)?_c('div',{staticClass:"item__data-type"},[_vm._v(" "+_vm._s(item.message_type)+" ")]):_vm._e(),(item.headline)?_c('div',{staticClass:"item__data-headline"},[_vm._v(" "+_vm._s(item.headline)+" ")]):_vm._e(),(item.message)?_c('div',{staticClass:"item__data-message",domProps:{"innerHTML":_vm._s(item.message)}}):_vm._e(),(item.action_button_url)?_c('div',{staticClass:"item__data-link-wrap"},[_c('a',{attrs:{"target":"_blank","href":_vm.isAndroidDevice && item.action_button_url_android
                      ? item.action_button_url_android
                      : _vm.isIOSDevice && item.action_button_url_iphone
                      ? item.action_button_url_iphone
                      : item.action_button_url}},[_vm._v(_vm._s(item.action_button_text))])]):_vm._e()])])}),0)]):_vm._e(),_c('div',{staticClass:"info-page__content"},[_c('div',{staticClass:"info-page__icon",class:{ promotion: _vm.isPromotion }},[(_vm.icon)?_c('img',{attrs:{"src":require("@/assets/img/icons/check-mark-icon.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/icons/reject.png"),"alt":""}})]),(_vm.expiredStatus)?_c('div',{staticClass:"info-page__title"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.expiredStatus)?_c('div',{staticClass:"info-page__desc"},[_vm._v(" If you need assistance, please reach out to Social Wonder’s team. ")]):_c('div',{staticClass:"info-page__title"},[_vm._v(" Thanks "+_vm._s(_vm.userName)+"! "),_c('br'),_vm._v(" "+_vm._s(_vm.message)+" ")]),(!_vm.expiredStatus)?_c('div',{staticClass:"info-page__desc"},[_vm._v(" We will schedule the approved posts for next week. ")]):_vm._e(),(_vm.$route.params.id)?_c('div',{staticClass:"info-page__link"},[_c('router-link',{attrs:{"to":{
              name: 'previous-feedback',
              params: { id: _vm.$route.params.id },
            }}},[_vm._v("Load previous feedback")])],1):_vm._e()])])]),(!_vm.isPromotion)?_c('Referrals',{attrs:{"id":"referralContainer","customerName":_vm.customerName}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-page__header"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo__img",attrs:{"src":require("@/assets/img/logo_full.svg")}})])])
}]

export { render, staticRenderFns }